
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyAWVVd3yrLCPFYn7fBKnjXhhrmy8vgIzxE",
    authDomain: "pauls-hotel.firebaseapp.com",
    projectId: "pauls-hotel",
    storageBucket: "pauls-hotel.appspot.com",
    messagingSenderId: "671037389429",
    appId: "1:671037389429:web:443152735f6c413617163c",
    measurementId: "G-Q3N9T31F1D"

};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;
import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import "./Home.css";
import ScrollToTop from "react-scroll-to-top";
// import Banquethall from "../images/banquet-hall.jpg"
import Lift from "../images/lift-paulshotel.jpg";
import maingate from "../images/main-gates.jpg";
import Banquet from '../inc/Banquet';
import Receptionpage from './Receptionpage';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import besthotelinkrishnanagar from "../images/paulshotelgate.jpeg";
import marriagekrishnanagar from "../images/reception-party.jpeg";
import liftgate from "../images/liftgate.jpg";
import room from "../images/room.jpeg";
import Marriagehallbooking from "../images/marriage book.jpeg"
import roomac from "../images/roomackrishnagar.jpeg";
import roombooking from "../images/roombooking.jpeg"
import Facility from './Facility';
import Makemaytrip from "../images/make-may-trip.jpg";
import Gobibo from "../images/gobibio.jpg";
import Conference from './Conference';

function Home() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div>

      <Navbar />
      <br />
      <br />
      <div className='album py-2'>
        <div className='container'>
          <h5>Pauls Hotel</h5>
        </div>
      </div>
      <Slider />
      <ScrollToTop smooth top="100" color="darkgoldenrod" />
      <div className='fonts'>
        <center><h1>Paul's Hotel & Banquet</h1></center>

      </div>

      <div className='album py-0'>
        <div className='container'>
          <div class="alert alert-dark" role="alert">

            <center>
              <div className='colorsb'>
                <h6> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                </svg> R.N Tagore Road, High Street, Krishnanagar, Nadia, 741101 (Near G.P Hero Showroom)</h6>
                <a href='tel: +919734448444'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708" />
                </svg> +91 97344 48444</a>
              </div>
              <br />
              <p> Best Hotel in Krishnagar, Room, Reception, Banquet Hall, Corporate Meeting, Ceminar Booking  </p>
            </center>

          </div>
        </div>
      </div>

      <div className='album py-1'>
        <div className='container'>
          <div className="row">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="col-md-6 mt-3">
             <a href='https://www.makemytrip.com/hotels/hotel-listing/?topHtlId=202404061410212067&city=CTKSN&country=IN&checkin=04272024&checkout=04282024&roomStayQualifier=2e0e&totalGuestCount=2&roomCount=1&cmp=googlehoteldfinder_DH_META_Free_default_IN_localuniversal_202404061410212067&_uCurrency=INR&Campaign=&locusId=CTKSN&locusType=city&mtkeys=9aa3c9e7-ea44-4165-aa22-90fa5c5303c8&au=' target="_blank" rel="noreferrer">
                <img src={Makemaytrip} className="card-img-top" alt="Makemaytrip"/>
                <div className="card-body">
                  <center>
                  <h5 className="card-title">Book Now</h5>
                  </center>


              
              </div>
              </a>
            </div>
            <div className="col-md-6 mt-3">
             <a href='https://www.goibibo.com/hotels/paul-s-hotel-banquet-hotel-in-krishnanagar-4544187690221751421?mHotelId=202404061410212067&checkin=2024-05-04&checkout=2024-05-05&roomString=1-2-0&cc=IN&locusId=CTKSN&locusType=city' target="_blank" rel="noreferrer">
                <img src={Gobibo} className="card-img-top" alt="Gobibo"/>
                <div className="card-body">
                  <center>
                  <h5 className="card-title">Book Now</h5>
                  </center>

              </div>
              </a>
            </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
<br/>
      <div className="fixedimage">
        <center>
          <div className='album py-0'>
            <div className='container'>
              <div className="row">
                <div className="col-md-12 mt-8" >
                  <div class="card border-light mb-3">
                    <div class="card-header">ROOM</div>
                    <div class="card-body text-dark">
                      <h5 class="card-title">Paul's Hotel & Banquet</h5>

                      <p class="card-text">AC Room, Deluxe Room, Conference, Meeting, Reception, Rice Ceremony, Birthday Booking Available</p>
                      <a href='/room'>
                        <button className="bookbutton me-2 mb-2" type="submit">Room Book <div className="spinner-grow text-light spinner-grow-sm" role="status">
                          <span className="visually-hidden"> Loading...</span></div>
                        </button>
                      </a>
                      <a href='/Reception'>
                        <button className="bookbutton" type="submit">Reception Book
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sentancestyle">

            <h1><b>PAUL'S HOTEL & BANQUET </b></h1><br />
          </div>
          <div className="sentancestyles">
            <h3>Best Hotel in Krishnagar</h3>
          </div>
        </center>
      </div>
      <br />
      <Facility />
      <div className='album py-3'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-4 mt-2">

              <div className="card" >
                <Banquet />
                {/* <img src={Banquethall} className="card-img-top" alt="Banquethall" /> */}
                <div className="card-body">
                  <center>
                    <h5 className="card-title">Banquet Hall</h5>
                  </center>
                  <p className="card-text">Best Banquet Hall in Krishnanagar Banquet Hall Booking Available,</p>

                </div>
              </div>

            </div>
            <div className="col-md-4 mt-2">

              <div className="card" >
                <img src={Lift} className="card-img-top" alt="Lift" />
                <div className="card-body">
                  <center>
                    <h5 className="card-title">Lift</h5>
                  </center>
                  <p className="card-text">Paul's Hotel & Banquet Lift Facility Available</p>
                </div>
              </div>

            </div>
            <div className="col-md-4 mt-2">

              <div className="card" >
                <img src={maingate} className="card-img-top" alt="maingate" />
                <div className="card-body">
                  <center>
                    <h5 className="card-title">Main Gate Ground</h5>
                  </center>
                  <p className="card-text">Paul's Hotel & Banquet Main Gate Ground Look</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="album py-1">

        <div className="container">

          <div className="row">

            <Carousel responsive={responsive}>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={besthotelinkrishnanagar} alt="besthotelinkrishnanagar" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Reception Gate Area</b></h6>
                  <p className="card-text"><small> Paul's Hotel & Banquet</small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>R.N tagor Road, High Street, Krishnanagar</small></p>

                  <a href='tel: +91 9734448444'>
                    <button className="btn-newbutton" type="submit">Book Now</button>
                  </a>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={marriagekrishnanagar} alt="marriagekrishnanagar" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Reception Gate Area</b></h6>
                  <p className="card-text"><small> Paul's Hotel & Banquet</small></p>

                  <p style={{ color: "Gray" }} className="card-text "><small>R.N tagor Road, High Street, Krishnanagar</small></p>

                  <a href='tel: +91 9734448444'>
                    <button className="btn-newbutton" type="submit">Book Now</button>
                  </a>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={liftgate} alt="liftgate" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Lift Gate</b></h6>
                  <p className="card-text"><small>Paul's Hotel & Banquet</small></p>

                  <p style={{ color: "Gray" }} className="card-text "><small>R.N tagor Road, High Street, Krishnanagar</small></p>
                  <a href='tel: +91 9734448444'>
                    <button className="btn-newbutton" type="submit">Book Now</button>
                  </a>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={room} alt="room" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>AC Room </b></h6>
                  <p className="card-text"><small>Paul's Hotel & Banquet</small></p>

                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <a href='tel: +91 9734448444'>
                    <button className="btn-newbutton" type="submit">Book Now</button>
                  </a>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={roomac} alt="roomac" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>AC Room </b></h6>
                  <p className="card-text"><small>Paul's Hotel & Banquet</small></p>

                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <a href='tel: +91 9734448444'>
                    <button className="btn-newbutton" type="submit">Book Now</button>
                  </a>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={roombooking} alt="roombooking" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>AC Room  </b></h6>
                  <p className="card-text"><small>Paul's Hotel & Banquet</small></p>

                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <a href='tel: +91 9734448444'>
                    <button className="btn-newbutton" type="submit">Book Now</button>
                  </a>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Marriagehallbooking} alt="Marriagehallbooking-krishnagar" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Reception Area</b></h6>
                  <p className="card-text"><small>Paul's Hotel & Banquet</small></p>

                  <p style={{ color: "Gray" }} className="card-text "><small>R.N tagor Road, High Street, Krishnanagar</small></p>
                  <a href='tel: +91 9734448444'>
                    <button className="btn-newbutton" type="submit">Book Now</button>
                  </a>
                </div>
              </div>
            </Carousel>
          </div>
        </div>

      </div>
     


      <hr/>
      <Conference/>
      <br/>
      <hr/>
      <br/>

      <Receptionpage />

      <div className="album py-2">
        <div className="container">
          <div className="row">
            <iframe src="https://www.google.com/maps/embed?pb=!4v1705241867589!6m8!1m7!1s3mWzRI5c6tGWEpWp_vweVg!2m2!1d23.41302734962299!2d88.4939684538749!3f83.7149907431366!4f11.62315641642222!5f0.7820865974627469" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

          </div>
        </div>
      </div>
      <br />
    </div>
  )
}

export default Home
import React from 'react'
import Navbar from '../inc/Navbar'
import Ricec from '../inc/Ricec'
import Rice from '../inc/Rice'
import ReactWhatsapp from "react-whatsapp";
import { Link } from "react-router-dom";
import GateRice from "../images/gatericeceremonypage.jpg";
import RiceceremonyPAGE from "../images/RICE_CEREMONY_GATE.jpg";
import Ricepage from "../images/pauls-hotel-gate.jpg";


function Riceceremony() {
  return (
    <div>
      <Navbar />
      <br />
      <br />

      <Rice />
      <br />
      <div className='album py-0'>
        <div className='container'>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li style={{ color: "darkgoldenrod" }} className="breadcrumb-item"><Link to="/" style={{ color: "darkgoldenrod" }} >Paul's Hotel</Link></li>
              <li style={{ color: "darkgoldenrod" }} className="breadcrumb-item"><Link to="/contacts" style={{ color: "darkgoldenrod" }} href="#">Contact</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Rice Ceremony <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
              </svg></li>
            </ol>
          </nav>
        </div>
      </div>

      <center>
      <div className='fonts'>
        <h2>Rice Ceremony at Paul's Hotel & Banquet, Krishnanagar</h2>
        </div>
      </center>
      <div className='album py-3'>
        <div className='container'>

          <div className="card mb-3" >
            <div className="row g-0">
              <div className="col-md-4">
                <Ricec />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h4 className="card-title"><b>Rice Ceremony  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                  </svg></b></h4>
                  <p className="card-text">No1 Reception Hall (Paul's Hotel) in Krishnagar at Highstreet</p>

                  <h5 className='mb-3'>Rice Ceremony Booking Price: - Price will be fixed after discuss</h5>
                  <a href='tel: +919734448444'>
                    <button className="button10 button11 me-2">Call Now</button>
                  </a>
                  <ReactWhatsapp number="+91  9734448444" className="button10 button11" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                  </svg>  WhatsApp</ReactWhatsapp>





                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='album py-0'>
        <div className='container'>
      <div className="card-group">
        <div className="card">
          <img src={GateRice} className="card-img-top" alt="GateRice" />
          <div className="card-body">
            <h5 className="card-title">Rice Ceremony Gate</h5>
            <p className="card-text">Best Loction, Best Rice Ceremony in Krishnagar, Pauls Hotel & Banquet</p>
          </div>
       
        </div>
        <div className="card">
          <img src={RiceceremonyPAGE} className="card-img-top" alt="Riceceremonypage" />
          <div className="card-body">
          <h5 className="card-title">Rice Ceremony Gate</h5>
            <p className="card-text">Best Loction, Best Rice Ceremony in Krishnagar, Pauls Hotel & Banquet</p>
          </div>
         
        </div>
        <div className="card">
          <img src={Ricepage} className="card-img-top" alt="paulshotel"/>
          <div className="card-body">
          <h5 className="card-title">Rice Ceremony Gate</h5>
            <p className="card-text">Best Loction, Best Rice Ceremony in Krishnagar, Pauls Hotel & Banquet</p>
          </div>
          
        </div>
      </div>
      </div>
      </div>
      <br/>
      <br/>
    </div>
  )
}

export default Riceceremony
import React from 'react'
import ScrollToTop from "react-scroll-to-top";
import {Link} from "react-router-dom";
import Navbar  from '../inc/Navbar';
import Conference from './Conference';
function ConferencePage() {
  return (
    <div>
          <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='album py-0'>
    <div className='container'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li style={{ color: "darkgoldenrod" }} className="breadcrumb-item"><Link to="/" style={{ color: "darkgoldenrod" }} >Paul's Hotel</Link></li>
                <li style={{ color: "darkgoldenrod" }} className="breadcrumb-item"><Link to="/room" style={{ color: "darkgoldenrod" }} >Room Book</Link></li>
                <li style={{ color: "darkgoldenrod" }} className="breadcrumb-item"><Link to="/Reception" style={{ color: "darkgoldenrod" }} >Reception</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Conference<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                </svg></li>
            </ol>
        </nav>
    </div>
</div>
            <ScrollToTop smooth top="100" color="darkgoldenrod" />

            <Conference/>
            <br/>
            <br/>
    </div>
  )
}

export default ConferencePage
import React from 'react'

import Conferencephoto from "../images/conference-meet.jpeg";
import Conferencephotomeetseminar from "../images/conference-photo.jpeg";
import paulshotel from "../images/paulshotel.jpeg";
import conferenceroom from "../images/conferenceroom.jpeg";



import meeting from "../images/CONFERENCE -PAULS.jpg"
import bestConferenekrishnagar from "../images/mettiing-room.jpeg"
import krishnagarmeetingroom from "../images/conference-picture.jpeg";
import bestmeetingroomkrishnagar from "../images/MEEET-KRISHNAGAR.jpeg";


function Conference() {
    return (
        <div>
          


            <div className='album py-0'>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="card-body">
                                <h3 className="card-title">Conference - (Corportae Metting, Any Event) </h3>
                                <h5 className="card-text mb-3">Book Available.</h5>
                                <div className="d-flex justify-content-start align-items-center mb-2">
                                    <a href="https://www.google.co.in/maps/dir//21,+R.N.Tagore+Road,+High+St,+Krishnanagar,+West+Bengal+741101/@23.4132139,88.4934465,19.38z/data=!4m8!4m7!1m0!1m5!1m1!1s0x39f92107d52b6c69:0x1cc8c6cbe476b6b7!2m2!1d88.4940513!2d23.4130135?entry=ttu" target="_blank" rel="noreferrer" className="conference me-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sign-merge-right" viewBox="0 0 16 16">
                                        <path d="M8.75 6v1q.211.451.588.95c.537.716 1.259 1.44 2.016 2.196l-.708.708-.015-.016c-.652-.652-1.33-1.33-1.881-2.015V12h-1.5V6H6.034a.25.25 0 0 1-.192-.41l1.966-2.36a.25.25 0 0 1 .384 0l1.966 2.36a.25.25 0 0 1-.192.41z" />
                                        <path fill-rule="evenodd" d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zm-1.4.7a.495.495 0 0 1 .7 0l6.516 6.515a.495.495 0 0 1 0 .7L8.35 14.866a.495.495 0 0 1-.7 0L1.134 8.35a.495.495 0 0 1 0-.7L7.65 1.134Z" />
                                    </svg> Direction</a>
                                    <a href='tel: +919734448444'>
                                        <button className="button12 button13 ">Book Now</button>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                 
                                        <div className="card">
                                            <img src={Conferencephoto} className="card-img-top" alt="Conferencephoto"/>
                                           
                                        </div>
                                
                                </div>
                                <div className="col-md-6  mt-3">
                                   
                                        <div className="card">
                                            <img src={Conferencephotomeetseminar} className="card-img-top" alt="Conferencephotomeet"/>
                                            
                                        </div>
                                 
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={paulshotel} className="card-img-top" alt="paulshotel"/>
                                        
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={conferenceroom} className="card-img-top" alt="conferenceroom"/>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            

                    </div>
                    
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                   
                                        <div className="card">
                                            <img src={meeting} className="card-img-top" alt="meeting"/>
                                           
                                        </div>
                                  
                                </div>
                                <div className="col-md-6  mt-3">
                                 
                                        <div className="card">
                                            <img src={bestConferenekrishnagar} className="card-img-top" alt="Conferenekrishnagar"/>
                                            
                                        </div>
                                   
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <div className="card">
                                        <img src={krishnagarmeetingroom} className="card-img-top" alt="krishnagarconferenceroom"/>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={bestmeetingroomkrishnagar} className="card-img-top" alt="bestmeetingroomkrishnagar"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            

                    </div>
         
         
        </div>
    )
}

export default Conference
import React, { useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'

function Sliderroom() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(9);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [sliderroomac, setSliderroomac] = useState([])
  const navigate = useNavigate()

  const sliderroomacCollectionRef = collection(db, "SliderRoomAc");
  useEffect(() => {

    const getSliderroomac = async () => {
      const data = await getDocs(sliderroomacCollectionRef);
      setSliderroomac(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getSliderroomac()
  }, [])
  return (
    <div>

      {sliderroomac.filter((val) => {
        if (setsearchTerm === "") {
          return val;
        } {
          return val;
        }
      }).slice(pagination.start, pagination.end).map((paulshotel) => {
        return (



          <div className="card-body">

<div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={paulshotel.slider1} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider2} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider3} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider4} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider5} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider6} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider7} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider5} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider8} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider9} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider10} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider11} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider12} className="d-block w-100" alt="Pauls-Hotel"/>
    </div>
 
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>










          </div>

        )
      })}



    </div>
  )
}

export default Sliderroom

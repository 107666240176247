import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";

import Footer from "./components/inc/Footer";
import Room from './components/pages/Room';
import Contacts from './components/pages/Contacts';
import Reception from './components/pages/Reception';
import Riceceremony from './components/pages/Riceceremony';
import Reviews from './components/pages/Reviews';
import Mayapur from './components/pages/Mayapur';
import ConferencePage from './components/pages/ConferencePage';


function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/room" element={<Room/>}/>
      <Route axact path="/contacts" element={<Contacts/>}/>
      <Route axact path="/Reception" element={<Reception/>}/>
      <Route axact path="/Riceceremony" element={<Riceceremony/>}/>
      <Route axact path="/Reviews" element={<Reviews/>}/>
      <Route axact path="/local-attraction-mayapur" element={<Mayapur/>}/>
      <Route axact path="/Conference" element={<ConferencePage/>}/>
   
      
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;

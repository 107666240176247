import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'

function Area() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(9);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [receptiongate, setReceptiongate] = useState([])
  const navigate = useNavigate()

  const receptiongateCollectionRef = collection(db, "ReceptionGate");
  useEffect(() => {

    const getReceptiongate = async () => {
      const data = await getDocs(receptiongateCollectionRef);
      setReceptiongate(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getReceptiongate()
  }, [])
  return (
    <div>

      {receptiongate.filter((val) => {
        if (setsearchTerm === "") {
          return val;
        } {
          return val;
        }
      }).slice(pagination.start, pagination.end).map((paulshotel) => {
        return (



          <div className="card-body">

            <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={paulshotel.slider1} className="d-block w-100" alt="no1 hotel in krishnanagar" />
                </div>
                <div className="carousel-item">
                  <img src={paulshotel.slider2} className="d-block w-100" alt="no1 hotel in krishnanagar" />
                </div>
                <div className="carousel-item">
                  <img src={paulshotel.slider3} className="d-block w-100" alt="no1 hotel in krishnanagar" />
                </div>
                <div className="carousel-item">
                <img src={paulshotel.slider4} className="d-block w-100" alt="no1 hotel in krishnanagar" />
              </div>
              <div className="carousel-item">
                <img src={paulshotel.slider5} className="d-block w-100" alt="no1 hotel in krishnanagar" />
              </div>
              <div className="carousel-item">
                <img src={paulshotel.slider6} className="d-block w-100" alt="no1 hotel in krishnanagar"/>
              </div>
              <div className="carousel-item">
                <img src={paulshotel.slider7} className="d-block w-100" alt="no1 hotel in krishnanagar" />
              </div>
              <div className="carousel-item">
                <img src={paulshotel.slider8} className="d-block w-100" alt="no1 hotel in krishnanagar" />
              </div>
              </div>
        
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>










          </div>

        )
      })}



    </div>
  )
}

export default Area

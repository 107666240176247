import React from 'react'
import "./Navbar.css";
import {Link} from "react-router-dom";
import Logo from "../images/pauls_logo.jpg";

function Navbar() {
  return (
    <div>
      <div className='fixed-top'>
        
      <div className='krishnagarhotel'>
          <div className="album py-2">
            <div className="container">
              <div className="row ">
                <div className="col-md-5" >
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <h4 style={{ color: "Darkblue" }} > <b> {ctime} </b></h4> */}

                    <a style={{color:"darkgoldenrod"}} href="tel: +91 9734448444"  > Any Questions? <svg xmlns="http://www.w3.org/2000/svg" style={{color:"darkgoldenrod"}} width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                    </svg> +91 9734-44-8444</a>


                    <a href="https://www.facebook.com/profile.php?id=100066818197752" target="_blank" rel="noopener noreferrer">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
</svg>
</a>
                    


                  </div>

                </div>

                <div className="d-flex justify-content-between align-items-center">





                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
  <div className="container-fluid ">
    <Link to="/"> 
    <img src={Logo} alt='logo' className='me-1' height="40" width="96"/>
    </Link>
    <Link to="/">
    <p style={{color:"maroon"}}> <small>PAUL'S HOTEL & BANQUET</small></p>
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" style={{color:"darkgoldenrod"}} className="nav-link active" aria-current="page" href="#">Home</Link>
        </li>
  
      
  
        <li className="nav-item">
          <Link to="/room" style={{color:"darkgoldenrod"}} className="nav-link " aria-current="page" href="#">Room</Link>
        </li>
        <li className="nav-item">
          <Link to="/Conference" style={{color:"darkgoldenrod"}} className="nav-link " aria-current="page" href="#">Conference</Link>
        </li>

        {/* <li className="nav-item dropdown">
          <a style={{color:"darkgoldenrod"}} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Room
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to="/room" className="dropdown-item" href="#">AC Room</Link></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li> */}
        <li className="nav-item">
          <Link to="/Reception" style={{color:"darkgoldenrod"}} className="nav-link " aria-current="page" >Reception</Link>
        </li>
        <li className="nav-item">
          <Link to="/Riceceremony" style={{color:"darkgoldenrod"}} className="nav-link " aria-current="page" >Rice Ceremony</Link>
        </li>
        <li className="nav-item">
          <Link to="/Reviews" style={{color:"darkgoldenrod"}} className="nav-link " aria-current="page" > Reviews</Link>
        </li>
        <li className="nav-item">
          <Link to="/local-attraction-mayapur" style={{color:"darkgoldenrod"}} className="nav-link " aria-current="page" >Local Attrections</Link>
        </li>
       
        <li className="nav-item">
          <Link to="/contacts" style={{color:"darkgoldenrod"}} className="nav-link " href="#" tabindex="-1" aria-disabled="true">Contact</Link>
        </li>
      </ul>
     
        <a href='tel: +91 9734448444'>
        <button className="btn-newbutton" type="submit">Book Now</button>
        </a>
    
    </div>
  </div>
</nav>
</div>
       </div>
  )
}

export default Navbar
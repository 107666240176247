import React, { useState, useEffect }  from 'react';

import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'

function Area() {
   const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [receptionarea, setReceptionarea] = useState([])
      const navigate = useNavigate()
  
      const receptionareaCollectionRef = collection(db, "ReceptionArea");
      useEffect(() => {
  
          const getReceptionarea = async () => {
              const data = await getDocs(receptionareaCollectionRef);
              setReceptionarea(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getReceptionarea()
      }, [])
  return (
    <div>

{receptionarea.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((paulshotel) => { return (
       
       
                       
                            <div className="card-body">

<div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="10000">
      <img src={paulshotel.slider1} className="d-block w-100" alt="krishnagarhotel"/>
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src={paulshotel.slider2} className="d-block w-100" alt="besthotelmayapore"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider3} className="d-block w-100" alt="krishnanagar_hotel"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider4} className="d-block w-100" alt="Reception_area"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider5} className="d-block w-100" alt="Reception_book_krishnanagar"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider6} className="d-block w-100" alt="bestbanquethallkrishnagar"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider7} className="d-block w-100" alt="bestbanquethallkrishnagar"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider8} className="d-block w-100" alt="bestbanquethallkrishnagar"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider9} className="d-block w-100" alt="bestbanquethallkrishnagar"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider10} className="d-block w-100" alt="bestbanquethallkrishnagar"/>
    </div>
    <div className="carousel-item">
      <img src={paulshotel.slider11} className="d-block w-100" alt="bestbanquethallkrishnagar"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
  
                                
                               
                              
                              
                               

                               
                    
                              
                            </div>
                           
                                )})} 
                            
                   
      
    </div>
  )
}

export default Area

import React from 'react'
import Riceceremony from "../images/RICECEREMONY.jpg";
import Riceceremonykrishnagar from "../images/ANNAPROSAN.jpg"

function Rice() {
  return (
    <div>
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Riceceremony} className="d-block w-100" alt="Riceceremony"/>
    </div>
    <div className="carousel-item">
      <img src={Riceceremonykrishnagar} className="d-block w-100" alt="Riceceremonykrishnagar"/>
    </div>
 
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Rice
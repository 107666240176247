import React from 'react'
import Navbar from '../inc/Navbar'
import mayapur from "../images/mayapur.jpg";
import Facility from './Facility';

function Mayapur() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
     
      <div className='album py-2'>
        <div className='container'>
          <h5>Pauls Hotel</h5>
          </div>
          </div>
      <img src={mayapur} className="d-block w-100" alt="pauls_hotel" />

      <br />
      <div className='album py-3'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-7 mt-2">

              <div className="card-body">
                <h3 className="card-title mb-2">PLACES TO VISIT IN MAYAPUR</h3>

                <h5 className="card-subtitle mb-4 text-muted">BE OUR GUEST AND MAKE EVERY STAY MEMORABLE</h5>
                <p className="card-text texts">Mayapur is a small town located in West Bengal, India. It is considered to be a holy place by many Hindus and is home to the ISKCON temple. If you’re looking for places to visit in Mayapur, Pulse Hotel is the most luxurious hotel in Krishnanagar. Hotel, reception & rice ceremony services are available. (Near G.P Hero Showroom)</p>


                

                <br />
                <br />
                <h2 className="card-title mb-2">Nabadwip & Mayapur:</h2>
                <p className='mb-1'>36 min (19.1 km) via Bhaktisiddhanta Saraswati Marg and NH12</p>
                <p className="card-text texts">Mayapur to Krishnanangar 15-20km (Approx)</p>
                <br />
                <h2 className="card-title mb-2">Fulia:</h2>
                <p>37 min (31.3 km) via NH12</p>
                <br />
                <h2 className="card-title mb-2"> Murshidabad & Palashi:</h2>
                <p>Respectively 102 Kms & 53 Kms. The world-famous places are known for Nabab Sirajddoulla, Robert Clive, Haazarduari Palace, and also for silk-textile art pieces.
                </p>
                <br />
                <h2 className="card-title mb-2"> Bethuadahari:</h2>
                <p>26 Kms. One of the well-known Reserve Forests in Bengal, with WILDLIFE and PICNIC facilities.
                </p>

                <br />
                <h1 className="card-title mb-2">And within the City of Krishnagar:</h1>
                <p className='mb-2'><b>Ghurni to Paul's hotel and Banquet 3 km</b></p>
                <p className='mb-2'><b>Challange more to Paul's hotel & banquet 300m</b></p>
                <p className='mb-2'><b>Station to 4 km</b></p>
                <p className='mb-2'><b>Bus stand to Pauls Hotel 1 km</b></p>
              </div>
            </div>
            <div className="col-md-5 mt-2">
              <div className="card text-dark bg-warning mb-3">
                <div className="card-header"><center>How to Reach Us</center></div>
                <div className="card-body">
                  <center>
                  <h5 className="card-title">Kolkata to Krishnagar 120 KM</h5>
                  <h5 className="card-text">Siliguri to Krishnagar 470 KM</h5>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <Facility />
    </div>
  )
}

export default Mayapur
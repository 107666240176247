import React from 'react'
import Banquethall from "../images/banquet-hall.jpg";
import banquetkrishnanagar from "../images/font-banquets.jpg";
import Krishnagarbanquethall from "../images/krishnagarbanquethall.jpg";

function Banquet() {
  return (
    <div>
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Banquethall} className="d-block w-100" alt="Best Banquet Hall Krishnanagar"/>
    </div>
    <div className="carousel-item">
      <img src={banquetkrishnanagar} className="d-block w-100" alt="banquet booking krishnagar"/>
    </div>
    <div className="carousel-item">
      <img src={Krishnagarbanquethall} className="d-block w-100" alt="Krishnagarbanquethall"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Banquet
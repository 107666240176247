import React from 'react'
import Navbar from '../inc/Navbar'
import Facility from './Facility'
import mainbanner from "../images/main-bannar-pauls.jpg";
import ScrollToTop from "react-scroll-to-top";
function Reviews() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <div className='album py-2'>
        <div className='container'>
          <h5>Pauls Hotel</h5>
          </div>
          </div>
          <ScrollToTop smooth top="100" color="darkgoldenrod" />
        <img src={mainbanner} className="d-block w-100" alt="pauls_hotel"/>
        <br/>
        <div className='album py-1'>
                <div className='container'>
                   <h3 className='mb-3'>Best Hotel in Krishnagar |  Best Clean & Safest Hotel in West Bengal</h3>
                   <a href='https://g.co/kgs/U3HWAqP' target="_blank" rel="noopener noreferrer">
                   <center><button className='btn-newbutton'> See All Reviewa in Google <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></button></center>
</a>
                </div>
            </div>
            <br/>

        <Facility/>
        <br/>
    </div>
  )
}

export default Reviews
import React from 'react'
import Navbar from '../inc/Navbar'
import Slider2 from '../inc/Slider2'
import { Link } from "react-router-dom";

import ScrollToTop from "react-scroll-to-top";

import Receptionpage from './Receptionpage';

function Reception() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <div className='album py-2'>
        <div className='container'>
          <h5>Pauls Hotel</h5>
          </div>
          </div>
      <Slider2 />
      <br />
      <ScrollToTop smooth top="100" color="darkgoldenrod" />
      <div className='album py-0'>
        <div className='container'>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li style={{ color: "darkgoldenrod" }} className="breadcrumb-item"><Link to="/" style={{ color: "darkgoldenrod" }} >Paul's Hotel</Link></li>
              <li style={{ color: "darkgoldenrod" }} className="breadcrumb-item"><Link to="/contacts" style={{ color: "darkgoldenrod" }} href="#">Contact</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Reception Page <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
              </svg></li>
            </ol>
          </nav>
        </div>
      </div>

     <Receptionpage/>

    </div>
  )
}

export default Reception
import React, { useState, useEffect } from 'react';

import {  useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'

function Area() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(9);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [receptiondining, setReceptiondining] = useState([])
    const navigate = useNavigate()

    const receptiondiningCollectionRef = collection(db, "ReceptionDining");
    useEffect(() => {

        const getReceptiondining = async () => {
            const data = await getDocs(receptiondiningCollectionRef);
            setReceptiondining(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getReceptiondining()
    }, [])
    return (
        <div>

            {receptiondining.filter((val) => {
                if (setsearchTerm === "") {
                    return val;
                } {
                    return val;
                }
            }).slice(pagination.start, pagination.end).map((paulshotel) => {
                return (



                    <div className="card-body">

                        <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="4" aria-label="Slide 5"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="5" aria-label="Slide 6"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="6" aria-label="Slide 7"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="7" aria-label="Slide 8"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="8" aria-label="Slide 9"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="9" aria-label="Slide 10"></button>


                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active" data-bs-interval="10000">
                                    <img src={paulshotel.slider1} className="d-block w-100" alt="paulshotel" />
                                    <div className="carousel-caption d-none d-md-block">

                                    </div>
                                </div>
                                <div className="carousel-item" data-bs-interval="2000">
                                    <img src={paulshotel.slider2} className="d-block w-100" alt="paulshotel" />
                                    <div className="carousel-caption d-none d-md-block">

                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={paulshotel.slider3} className="d-block w-100" alt="paulshotel" />
                                    <div className="carousel-caption d-none d-md-block">

                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={paulshotel.slider4} className="d-block w-100" alt="paulshotel" />
                                    <div className="carousel-caption d-none d-md-block">

                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={paulshotel.slider5} className="d-block w-100" alt="paulshotel" />
                                    <div className="carousel-caption d-none d-md-block">

                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={paulshotel.slider6} className="d-block w-100" alt="paulshotel" />
                                    <div className="carousel-caption d-none d-md-block">

                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={paulshotel.slider7} className="d-block w-100" alt="paulshotel" />
                                    <div className="carousel-caption d-none d-md-block">

                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={paulshotel.slider8} className="d-block w-100" alt="paulshotel" />
                                    <div className="carousel-caption d-none d-md-block">

                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={paulshotel.slider9} className="d-block w-100" alt="paulshotel" />
                                    <div className="carousel-caption d-none d-md-block">

                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={paulshotel.slider10} className="d-block w-100" alt="paulshotel" />
                                    <div className="carousel-caption d-none d-md-block">

                                    </div>
                                </div>
                                
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>










                    </div>

                )
            })}



        </div>
    )
}

export default Area

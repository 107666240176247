import React from 'react'
import Navbar from '../inc/Navbar'
import main_building from "../images/main-building.jpg";
import Roomac from "../images/rooms-ac.jpeg";
import Deluxeroomkrishnanagar from "../images/deluxe-room.jpg";
import { Link } from "react-router-dom";

import "./Room.css";
import ScrollToTop from "react-scroll-to-top";

import Sliderroom from '../inc/Sliderroom';
import Deluxe from '../inc/Deluxe';
import Facility from './Facility';


function Room() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
     
      <div className='album py-2'>
        <div className='container'>
          <h5>Pauls Hotel</h5>
          </div>
          </div>
      <img src={main_building} width="100%" alt="krishnanagar-hotel" />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="darkgoldenrod" />
      <div className='album py-0'>
        <div className='container'>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li style={{ color: "darkgoldenrod" }} className="breadcrumb-item"><Link to="/" style={{ color: "darkgoldenrod" }} >Paul's Hotel</Link></li>
              <li style={{ color: "darkgoldenrod" }} className="breadcrumb-item"><Link to="/contacts" style={{ color: "darkgoldenrod" }} href="#">Contact</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Room Page <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
              </svg></li>
            </ol>
          </nav>
        </div>
      </div>

      <center>
      <div className='fonts'>
        <h2>Guest Rooms at Paul's Hotel & Banquet, Krishnanagar</h2>
        </div>
      </center>

      <div className='album py-3'>
        <div className='container'>

          <div className="card mb-3" >
            <div className="row g-0">
              <div className="col-md-4">
                <img src={Roomac} className="img-fluid rounded-start" alt="AC-Room" />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h4 className="card-title"><b>AC Silver Room <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                  </svg></b></h4>
                  <p className="card-text">Category - Ac Room,  Best, Top & Clean AC Room in Krishnanagar at Highstreet</p>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <p className="card-text mb-2"><small className="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                      <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                      <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                    </svg> Check in - 12:00 PM </small></p>
                    <p className="card-text mb-2"><small className="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                      <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                      <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                    </svg> Check out - 10:00 AM </small></p>
                  </div>
                  <h5 className='mb-2'>Room Price: - 1200 to 1500 + GST</h5>


                  <button type="button" className="button10 button11" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check2-square" viewBox="0 0 16 16">
                      <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z" />
                      <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0" />
                    </svg> VIEW PHOTOS (12)
                  </button>
                  <a href='tel: +919734448444'>
                    <button className="button10 button11 me-2">Book Now</button>
                  </a>

                  <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdropLabel"></h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <Sliderroom />
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='album py-3'>
        <div className='container'>

          <div className="card mb-3" >
            <div className="row g-0">
              <div className="col-md-4">
                <img src={Deluxeroomkrishnanagar} className="img-fluid rounded-start" alt="Deluxe-room-krishnanagar" />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h4 className="card-title"><b>Deluxe AC Room <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                  </svg></b></h4>

                  <p className="card-text">Category - Deluxe, Double Bed & Four Bed both, Best, Top & Clean Deluxe Room in Krishnagar at Highstreet</p>
                  <div className="d-flex justify-content-between align-items-center ">
                    <p className="card-text mb-2"><small className="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                      <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                      <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                    </svg> Check in - 12:00 PM </small></p>
                    <p className="card-text mb-2"><small className="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                      <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                      <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                    </svg> Check out - 10:00 AM </small></p>
                  </div>
                  <h5 className='mb-2'>Double Bed Tariff - 2000 upto 2500 + GST</h5>
                  <h5 className='mb-2'>FOUR Bed Tariff - 3000 upto 3200+ GST</h5>





                  <button type="button" className="button10 button11" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check2-square" viewBox="0 0 16 16">
                      <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z" />
                      <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0" />
                    </svg> VIEW PHOTOS (12)
</button>
<a href='tel: +919734448444'>
                    <button className="button10 button11 me-2">Book Now</button>
                  </a>


<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">

        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
     <Deluxe/>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

      </div>
    </div>
  </div>
</div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Facility/>
      <br/>
    </div>
  )
}

export default Room
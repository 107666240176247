import React, { useState, useEffect }  from 'react';

import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'

function Ricec() {
   const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [riceceremony, setRiceceremony] = useState([])
      const navigate = useNavigate()
  
      const riceceremonyCollectionRef = collection(db, "RiceCeremony");
      useEffect(() => {
  
          const getRiceceremony = async () => {
              const data = await getDocs(riceceremonyCollectionRef);
              setRiceceremony(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getRiceceremony()
      }, [])
  return (
    <div>

{riceceremony.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((paulshotel) => { return (
       
       
                       
                            <div className="card-body">
                                   
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>

    
    
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={paulshotel.slider1} className="d-block w-100" alt="pauls_hotel"/>
        </div>
        <div className="carousel-item">
          <img src={paulshotel.slider2} className="d-block w-100" alt="pauls_hotel"/>
        </div>
       
        <div className="carousel-item">
          <img src={paulshotel.slider3} className="d-block w-100" alt="pauls_hotel"/>
        </div>
      
      <div className="carousel-item">
          <img src={paulshotel.slider4} className="d-block w-100" alt="pauls_hotel"/>
        </div>
   
       
      </div>
     
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  
                                
                               
                              
                              
                               

                               
                    
                              
                            </div>
                           
                                )})} 
                            
                   
      
    </div>
  )
}

export default Ricec
